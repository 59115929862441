<template>
  <div class="meetSetting">
    <div class="header" @click="go('user')" v-if="loginFinished">
      <div class="headerBox">
        <img class="img" :src="userInfo.avatar?userInfo.avatar:require('../../assets/images/headerImg.png')" alt="" />
        <div class="info">
          <div class="name">{{ userInfo.realName }}</div>
          <div class="tel">{{ userInfo.phone }}</div>
          <div class="email">{{ userInfo.email }}</div>
        </div>
      </div>

      <img class="rightImg" src="../../assets/images/right.png" alt="" />
    </div>
    <div class="item" @click="go('showMeetTime')">
      <div class="tit">{{ $t("seeting.meet") }}</div>
      <img class="rightImg" src="../../assets/images/right.png" alt="" />
    </div>
    <div class="item" @click="go('currency')">
      <div class="tit">{{ $t("seeting.currency") }}</div>
      <img class="rightImg" src="../../assets/images/right.png" alt="" />
    </div>
    <div class="item" @click="go('about')">
      <div class="tit">{{ $t("seeting.about") }}</div>
      <img class="rightImg" src="../../assets/images/right.png" alt="" />
    </div>
    <div class="back" @click="back()">{{ $t("common.back") }}</div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
export default {
  data() {
    return {
      userInfo: {},
      loginFinished: false,
    };
  },
  mounted() {
   if (getToken()) {
     this.loginFinished = true
     this.getUserInfo();
   } else {
     this.loginFinished = false
   }
  },
  methods: {
    getUserInfo() {
      sdk.meetSetting.getUserInfo().then((res) => {
        this.userInfo = res;
        this.$store.commit("user/setUserInfo", res);
      });
    },
    go(path) {
      this.$router.push({ name: path });
    },
    back() {
      if (getToken()) {
        this.$router.push({ name: 'home' });
      } else {
        this.$router.push({ name: 'index' });
      }
    },
  },
};
</script>
<style scoped lang='less'>
.meetSetting {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fafafa;
  .rightImg {
    width: 32px;
    height: 32px;
  }
  .header {
    height: 200px;
    margin-top: 16px;

    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    .headerBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .img {
        width: 110px;
        height: 110px;
      }
      .info {
        margin-left: 20px;
      }
      .name {
        font-size: 32px;
        color: #000;
      }
      .tel,
      .email {
        font-size: 28px;
        color: #999;
      }
    }
  }
  .item {
    background: #fff;
    margin-top: 16px;
    height: 104px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    .tit {
      font-size: 32px;
      color: #333;
    }
  }
  .back {
    margin-top: 80px;
    background: #fff;
    text-align: center;
    line-height: 88px;
    font-size: 32px;
    width: 622px;
    height: 88px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    margin-left: 64px;
    color: #333;
  }
}
</style>
